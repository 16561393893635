import { iCompanyProfileCardProps } from "../dto/model";
import { CompanyProfileCardHeader } from "./CompanyProfileCardHeader";
import { CompanyProfileCardBody } from "./CompanyProfileCardBody";

export const CompanyProfileCard = ({
  receivedCompany,
  theme,
}: iCompanyProfileCardProps) => {
  return (
    <div className="company__profile">
      <CompanyProfileCardHeader
        theme={theme}
        receivedCompany={receivedCompany}
      />
      <CompanyProfileCardBody theme={theme} receivedCompany={receivedCompany} />
    </div>
  );
};
