import { DevJobsSwitch } from "../../app/devjobs/components/DevJobsSwitch";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const Header = () => {
  const location = useLocation();
  return (
    <header>
      <img
        src="/images/bg-pattern-header.svg"
        className="w-100 d-none d-md-block"
        alt="Header background"
      />
      <img
        src="/images/bg-pattern-header-mobile.svg"
        className="w-100 d-md-none"
        alt="Header background"
      />
      <Container
        style={{
          marginBottom: `${
            location?.pathname === "/company-profile" && "4rem"
          }`,
        }}
      >
        <DevJobsSwitch />
      </Container>
    </header>
  );
};
