import { Button } from "react-bootstrap";
import { iCompanyProfileCardProps } from "../dto/model";

export const CompanyProfileCardBody = ({
  theme,
  receivedCompany,
}: iCompanyProfileCardProps) => {
  return (
    <div
      className={`company__body ${
        theme === "dark-theme" ? "bg-dark-blue" : "bg-white"
      }`}
    >
      <div className="company__title">
        <div>
          <p className="text-muted">
            {receivedCompany?.postedAt} • {receivedCompany?.contract}
          </p>
          <h2
            className={`fw-bold ${
              theme === "dark-theme" ? "text-white" : "text-dark-blue"
            }`}
          >
            {receivedCompany?.position}
          </h2>
          <p className="text-primary fw-bold">{receivedCompany?.location}</p>
        </div>
        <Button variant="primary px-5 py-3 w-md-25">Apply now</Button>
      </div>
      <div className="company__description">
        <p className="text-muted">{receivedCompany?.description}</p>
        <h4
          className={`fw-bold mb-4 mt-5 ${
            theme === "dark-theme" ? "text-white" : "text-dark-blue"
          }`}
        >
          Requirements
        </h4>
        <p className="text-muted">{receivedCompany?.requirements?.content}</p>
        <ul className="text-muted">
          {receivedCompany?.requirements?.items?.map((requirement) => (
            <li className="my-2">{requirement}</li>
          ))}
        </ul>
        <h4
          className={`fw-bold mb-4 mt-5 ${
            theme === "dark-theme" ? "text-white" : "text-dark-blue"
          }`}
        >
          What You Will Do
        </h4>
        <p className="text-muted">{receivedCompany?.role?.content}</p>
        <ol className="text-muted" id="custom-ol-numbering">
          {receivedCompany?.role?.items?.map((role) => (
            <li className="my-2">{role}</li>
          ))}
        </ol>
      </div>
    </div>
  );
};
