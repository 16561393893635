import { Button, Container } from "react-bootstrap";
import { iDevJobs } from "../../app/devjobs/dto/model";

interface iFooterProps {
  receivedCompany: iDevJobs | undefined;
  theme: string;
}

export const Footer = ({ receivedCompany, theme }: iFooterProps) => {
  return (
    <Container className="d-flex align-items-center justify-content-between flex-column flex-md-row">
      <div className="d-none d-md-block">
        <h2
          className={`fw-bold ${
            theme === "dark-theme" ? "text-white" : "text-dark-blue"
          }`}
        >
          {receivedCompany?.position}
        </h2>
        <p className="text-primary fw-bold">{receivedCompany?.location}</p>
      </div>
      <a href={receivedCompany?.apply} target="_blank" className="w-md-25">
        <Button variant="primary px-5 py-3 w-100">Apply now</Button>
      </a>
    </Container>
  );
};
