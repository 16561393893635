import React from "react";
import { Route, Routes } from "react-router-dom";
import { DevJobs } from "./devjobs";
import { CompanyProfile } from "./profile";

export const BaseRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DevJobs />} />
      <Route path="/company-profile" element={<CompanyProfile />} />
    </Routes>
  );
};
