import { Header } from "../../shared/layout/Header";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { iDevJobs } from "../devjobs/dto/model";
import { UserTheme } from "../../App";
import { Footer } from "../../shared/layout/Footer";
import { CompanyProfileCard } from "./components/CompanyProfileCard";

export const CompanyProfile = () => {
  const { theme }: any = useContext(UserTheme);

  const [receivedCompany, setReceivedCompany] = useState<iDevJobs>();
  const { state } = useLocation();
  useEffect(() => {
    state && setReceivedCompany(state as iDevJobs);
  }, [JSON.stringify(state)]);

  return (
    <>
      <Header />
      <Container>
        <CompanyProfileCard theme={theme} receivedCompany={receivedCompany} />
      </Container>
      <footer
        className={`${theme === "dark-theme" ? "bg-dark-blue" : "bg-white"}`}
      >
        <Footer theme={theme} receivedCompany={receivedCompany} />
      </footer>
    </>
  );
};
