import { Button, Col, Container, Row } from "react-bootstrap";
import { DevJobsInputs } from "./DevJobsInputs";
import { DevJobsCards } from "./DevJobsCards";
import { useEffect, useState } from "react";
import { iDevJobs } from "../dto/model";
import { JSON_DATA } from "../../../utils/helpers/json";

export const DevJobsFiltering = () => {
  const [filteredArray, setFilteredArray] = useState<iDevJobs[]>();
  const [devJobsData, setDevJobsData] = useState<iDevJobs[]>();
  const [dataToCompare, setDataToCompare] = useState({
    title: "",
    location: "",
    fullTime: false,
  });
  const [isShowMore, setIsShowMore] = useState(false);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    setDataToCompare((prev) => ({
      ...prev,
      [event.target?.name]:
        event.target.name === "fullTime"
          ? event.target.checked
          : event.target.value,
    }));
  };

  const searchData = () => {
    if (
      dataToCompare?.title &&
      dataToCompare?.location &&
      dataToCompare?.fullTime
    ) {
      let filtered = devJobsData?.filter(
        (job) =>
          job?.position
            .toLowerCase()
            .trim()
            .includes(dataToCompare?.title?.toLowerCase().trim() as string) &&
          job?.location
            .toLowerCase()
            .trim()
            .includes(
              dataToCompare?.location?.toLowerCase().trim() as string
            ) &&
          job?.contract === "Full Time"
      );
      setFilteredArray(filtered);
    } else if (dataToCompare?.title && dataToCompare?.location) {
      let filtered = devJobsData?.filter(
        (job) =>
          job?.position
            .toLowerCase()
            .trim()
            .includes(dataToCompare?.title?.toLowerCase().trim() as string) &&
          job?.location
            .toLowerCase()
            .trim()
            .includes(dataToCompare?.location?.toLowerCase().trim() as string)
      );
      setFilteredArray(filtered);
    } else if (dataToCompare?.fullTime) {
      let filteredContract = devJobsData?.filter(
        (job) => job.contract === "Full Time"
      );
      setFilteredArray(filteredContract);
    } else {
      let filteredOne = devJobsData?.filter((job) =>
        job[dataToCompare?.title ? "position" : "location"]
          .toLocaleLowerCase()
          .trim()
          .includes(
            (dataToCompare?.title
              ? dataToCompare?.title?.toLowerCase().trim()
              : dataToCompare?.location?.toLowerCase().trim()) as string
          )
      );
      setFilteredArray(filteredOne);
    }
  };

  useEffect(() => {
    setDevJobsData(JSON_DATA);
    setFilteredArray(JSON_DATA);
  }, []);

  return (
    <Container className="main__container">
      <div className="main__content">
        <DevJobsInputs changeHandler={changeHandler} searchData={searchData} />
      </div>
      <div className="main__jobCards">
        <Row className="m-0">
          {isShowMore
            ? filteredArray
                ?.slice(0)
                ?.map((job, i) => <DevJobsCards key={i} jobs={job} />)
            : filteredArray
                ?.slice(0, 12)
                ?.map((job, i) => <DevJobsCards jobs={job} key={i} />)}
          {!isShowMore &&
            !(
              dataToCompare?.title ||
              dataToCompare?.location ||
              dataToCompare?.fullTime
            ) && (
              <Col md={12} className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  className="px-3 mb-3"
                  onClick={() => setIsShowMore(true)}
                >
                  Load more
                </Button>
              </Col>
            )}
        </Row>
      </div>
    </Container>
  );
};
