import { Col } from "react-bootstrap";
import { useContext } from "react";
import { UserTheme } from "../../../App";
import { iDevJobsProps } from "../dto/model";
import { Link } from "react-router-dom";

export const DevJobsCards = ({ jobs }: iDevJobsProps) => {
  const { theme }: any = useContext(UserTheme);
  return (
    <Col md={4} className="position-relative my-4 d-flex flex-column">
      <Link
        to="/company-profile"
        state={jobs}
        className=" d-flex align-items-stretch h-100"
      >
        <div
          className={`main__cardsBody w-100 ${
            theme === "dark-theme" ? "bg-dark-blue" : "light-theme"
          }`}
        >
          <p className="text-light-gray mt-3 mb-1">
            {jobs?.postedAt} • {jobs?.contract}
          </p>
          <h3
            className={`${
              theme === "dark-theme" ? "text-white" : "text-dark-blue"
            } fw-bolder`}
          >
            {jobs?.position}
          </h3>
          <p className="text-light-gray">{jobs?.company}</p>
          <p className="text-primary pt-3 mb-0 fw-bold">{jobs?.location}</p>
        </div>
        <div
          className="main__cardsLogo"
          style={{ background: `${jobs?.logoBackground}` }}
        >
          <img src={jobs?.logo} className="w-75" alt="Company logo" />
        </div>
      </Link>
    </Col>
  );
};
