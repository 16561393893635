interface iInputField {
  type: string;
  placeHolder: string;
  className: string;
  name: string;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputField = ({
  type,
  placeHolder,
  className,
  changeHandler,
  name,
}: iInputField) => {
  return (
    <input
      type={type}
      className={`input-field ${className}`}
      name={name}
      onChange={changeHandler}
      placeholder={placeHolder}
    />
  );
};
