import { Button, Col, Row } from "react-bootstrap";
import { iCompanyProfileCardProps } from "../dto/model";

export const CompanyProfileCardHeader = ({
  receivedCompany,
  theme,
}: iCompanyProfileCardProps) => {
  return (
    <Row className="box-shadow mx-0">
      <Col
        md={2}
        className="d-flex align-items-center justify-content-center py-2"
        style={{ background: `${receivedCompany?.logoBackground}` }}
      >
        <img
          src={`${receivedCompany?.logo}`}
          className="w-30"
          alt="Company logo"
        />
      </Col>
      <Col
        md={10}
        className={` p-3 d-flex align-items-center justify-content-between flex-column flex-md-row ${
          theme === "dark-theme" ? "bg-dark-blue" : "bg-white"
        }`}
      >
        <div className="ps-md-5 text-center text-md-start">
          <h3
            className={`fw-bold ${
              theme === "dark-theme" ? "text-white" : "text-dark-blue"
            }`}
          >
            {receivedCompany?.company}
          </h3>
          <h6 className="text-muted">{receivedCompany?.website}</h6>
        </div>
        <a href={receivedCompany?.apply} target="_blank">
          <Button variant="light-violet" className="text-primary me-md-5">
            Company Site
          </Button>
        </a>
      </Col>
    </Row>
  );
};
