import { Header } from "../../shared/layout/Header";
import { DevJobsFiltering } from "./components/DevJobsFiltering";

export const DevJobs = () => {
  return (
    <>
      <Header />
      <DevJobsFiltering />
    </>
  );
};
