import React, {
  createContext,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { BaseRoutes } from "./app/routes";

//@ts-ignore
export const UserTheme = createContext();

export interface iThemeProvider {
  theme: string;
  setTheme: () => void;
}

function App() {
  const currentTheme = localStorage.getItem("theme");
  const body = document.body;
  const [theme, setTheme] = useState<string | null>(currentTheme);

  useEffect(() => {
    if (theme !== "dark-theme") {
      localStorage.setItem("theme", "light-theme");
      body.style.setProperty("--themeColor", "#F2F2F2");
    } else {
      localStorage.setItem("theme", "dark-theme");
      body.style.setProperty("--themeColor", "#121721");
    }
  }, [theme]);

  return (
    <UserTheme.Provider value={{ theme, setTheme }}>
      <BrowserRouter>
        <BaseRoutes />
      </BrowserRouter>
    </UserTheme.Provider>
  );
}

export default App;
