import { Button, Col, Form, Row } from "react-bootstrap";
import { useContext } from "react";
import { UserTheme } from "../../../App";
import { InputField } from "../../../shared/elements/InputField";
import { iDevJobsInputsProps } from "../dto/model";

export const DevJobsInputs = ({
  changeHandler,
  searchData,
}: iDevJobsInputsProps) => {
  const { theme }: any = useContext(UserTheme);
  return (
    <div
      className={`main__searchFields ${
        theme === "dark-theme" ? "bg-dark-blue" : "light-theme"
      }`}
    >
      <Row>
        <Col
          md={4}
          className={`d-flex ${
            theme === "dark-theme" ? "border-right-dark" : "border-md-end"
          }`}
        >
          <div className="d-flex align-items-center justify-content-around w-100">
            <img
              src="/images/icon-search.svg"
              className="me-2"
              alt="Search icon"
            />
            <InputField
              type="text"
              name="title"
              changeHandler={changeHandler}
              className={`${
                theme === "dark-theme"
                  ? "bg-dark-blue text-white"
                  : "light-theme"
              }`}
              placeHolder="Filter by title, companies, expertise…"
            />
          </div>
        </Col>
        <Col
          md={4}
          className={`d-flex ${
            theme === "dark-theme" ? "border-right-dark" : "border-md-end"
          }`}
        >
          <div className="d-flex align-items-center justify-content-around w-100">
            <img
              src="/images/icon-location.svg"
              className="me-2"
              alt="Location icon"
              id="location-icon"
            />
            <InputField
              type="text"
              name="location"
              changeHandler={changeHandler}
              className={`${
                theme === "dark-theme"
                  ? "bg-dark-blue text-white"
                  : "light-theme"
              }`}
              placeHolder="Filter by location…"
            />
          </div>
        </Col>
        <Col
          md={4}
          className="d-flex d-md-block flex-column align-items-center justify-content-center py-2 py-md-0"
        >
          <Form.Check
            inline
            label="Full Time Only"
            name="fullTime"
            onClick={changeHandler}
            className="mb-3 mb-md-0"
            type="checkbox"
            id={`${
              theme === "dark-theme"
                ? "inline-checkbox-dark"
                : "inline-checkbox-light"
            }`}
          />
          <Button variant="primary" className="w-50" onClick={searchData}>
            Search
          </Button>
        </Col>
      </Row>
    </div>
  );
};
